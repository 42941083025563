import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { offer, PERCENTAGE, propTypes } from '../../../util/types';
import { isTooManyRequestsError } from '../../../util/errors';

import { Form, PrimaryButton, FieldTextInput, IconInquiry, Heading, FieldRadioButton, FieldSelect, FieldCurrencyInput } from '../../../components';

import css from './InquiryForm.module.css';
import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { compensationPercentage } from '../../../config/configListing';

const ErrorMessage = props => {
  const { error } = props;
  // No transaction process attached to listing
  return error ? (
    <p className={css.error}>
      {error.message === 'No transaction process attached to listing' ? (
        <FormattedMessage id="InquiryForm.sendInquiryErrorNoProcess" />
      ) : isTooManyRequestsError(error) ? (
        <FormattedMessage id="InquiryForm.tooManyRequestsError" />
      ) : (
        <FormattedMessage id="InquiryForm.sendInquiryError" />
      )}
    </p>
  ) : null;
};

// NOTE: this InquiryForm is only for booking & purchase processes
// The default-inquiry process is handled differently
const InquiryFormComponent = props => (
  <FinalForm
    {...props}
    keepDirtyOnReinitialize
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        submitButtonWrapperClassName,
        formId,
        handleSubmit,
        inProgress,
        intl,
        listingTitle,
        authorDisplayName,
        sendInquiryError,
        compPercentage,
        compPrice,
        sendInquiryInProgress,
        proposalAnswer,
        compensationOffer,
        values
      } = fieldRenderProps;
      const modalHeadingForAmount = compensationOffer == PERCENTAGE
        ? `percentage and current percentage is ${compPercentage}`
        : `price and current price is $${compPrice}`;
      const messageLabel = intl.formatMessage(
        {
          id: 'InquiryForm.messageLabel',
        },
        { authorDisplayName }
      );
      const messagePlaceholder = intl.formatMessage(
        {
          id: 'InquiryForm.messagePlaceholder',
        },
        { authorDisplayName }
      );
      const messageRequiredMessage = intl.formatMessage({
        id: 'InquiryForm.messageRequired',
      });
      const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress;
      const config = useConfiguration();
      const marketplaceCurrency = config.currency;

      return (
        <Form className={classes} onSubmit={() => { handleSubmit(values, proposalAnswer) }} enforcePagePreloadFor="OrderDetailsPage">
          <IconInquiry className={css.icon} />
          <Heading as="h2" rootClassName={css.heading}>
            <FormattedMessage id="InquiryForm.heading" values={{ listingTitle }} />
          </Heading>
          <FieldTextInput
            className={css.field}
            type="textarea"
            name="message"
            id={formId ? `${formId}.message` : 'message'}
            label={messageLabel}
            placeholder={messagePlaceholder}
            validate={messageRequired}
          />

          <p>{intl.formatMessage({ id: 'TransactionPanel.CounterModalHeading' })} {modalHeadingForAmount}</p>

          {offer.map((st, i) => {
            const type = st.userType;
            return (
              <div
                key={st.label + '_' + i}
              >
                <FieldRadioButton
                  id={st.option}
                  key={st.option}
                  name="compensationOffer"
                  value={st.option}
                  label={st.label}
                  validate={validators.required(
                    intl.formatMessage({ id: 'CounterOfferForm.CounterMethod' })
                  )}
                />
              </div>
            );
          })}

          {values.compensationOffer == PERCENTAGE
            ? (
              <FieldSelect
                className={css.selectCompensation}
                id={formId ? `${formId}.compPercentage` : 'compPercentage'}
                name="compPercentage"
                label={intl.formatMessage({ id: 'SignupForm.compPercentageLabel' })}
                validate={validators.required(
                  intl.formatMessage({ id: 'CounterOfferForm.percentageRequired' })
                )}
              >
                <option disabled value="">
                  {intl.formatMessage({
                    id: 'SignupForm.compPercentagePlaceholder',
                  })}
                </option>
                {compensationPercentage.map(st => {
                  return (
                    <option key={st.option} value={st.option}>
                      {st.label}
                    </option>
                  );
                })}
              </FieldSelect>
            )
            : (
              <FieldCurrencyInput
                className={css.selectCompensation}
                id={formId ? `${formId}.compPrice` : 'compPrice'}
                name="compPrice"
                label={intl.formatMessage({ id: 'SignupForm.compPriceLabel' })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.compPricePlaceholder',
                })}
                currencyConfig={appSettings.getCurrencyFormatting(marketplaceCurrency)}
                validate={validators.required(
                  intl.formatMessage({ id: 'CounterOfferForm.CurrencyRequired' })
                )}
              />
            )}
          <br />
          <div className={submitButtonWrapperClassName}>
            <ErrorMessage error={sendInquiryError} />
            <PrimaryButton type="submit"
              inProgress={sendInquiryInProgress}
              disabled={submitDisabled}>
              <FormattedMessage id="InquiryForm.submitButtonText" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

InquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  sendInquiryError: null,
};

InquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,

  listingTitle: string.isRequired,
  authorDisplayName: string.isRequired,
  sendInquiryError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const InquiryForm = compose(injectIntl)(InquiryFormComponent);

InquiryForm.displayName = 'InquiryForm';

export default InquiryForm;